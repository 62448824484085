
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App{
  font-family: "Inter", sans-serif;
}

.App-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 1rem;
  flex-wrap: wrap;
  min-height: 100vh;
}

.restaurant-social-group-img-2{
  width: 55%;
  height: auto;
  justify-self: end; 
}

.logo-header{
  height: auto;
  width: 100%;
  box-shadow: none !important;
}

.App-link {
  color: #61dafb;
}

.logo-and-text{
  display: flex;
  justify-items: center;
  flex-wrap: wrap;
  width: 30%;
}

.logo-and-text h1{
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 2rem;
  line-height: 1.4;
  background-color: #062a3794;
  color: white;
  /* opacity: 0.5; */
  padding: 1rem;
}

main{
  text-align: center;
  margin-bottom: 10rem;
  /* margin-top: 10rem; */
}

.main-img{
  width: 80%;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

main h2{
  font-size: 2rem;
  font-weight: 400;
}

.App img{
  box-shadow: 20px 10px 10px black;
}

footer{
  background-color: #191c1f;
  color: white;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 3rem;
}

footer p{
  text-align: center;
  font-size: 1rem;
}

.desc-p{
  width: 60%;
  margin: 0 auto;
  margin-top: 0rem;
  margin-bottom: 10rem;
  font-size: 1.2rem;
  line-height: 2;
  font-weight: 400;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1000px){ 
  .logo-and-text{
    width: 80%;
    margin-bottom: 2rem;
    min-height: 100vh;
    align-content: center;
    align-items: flex-start;
  }

  .restaurant-social-group-img-2{
    width: 90%;
  }

  .desc-p{
    margin-top: 10rem;
  }
}